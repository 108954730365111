.report-modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  top: 0;
  pointer-events: none;
  opacity: 0;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0;
  }
  &.open{
    pointer-events: auto;
    opacity: 1;
    &:before{
      opacity: 0.32;
    }
  }
}
.report-modal__wrapper{
  padding: 40px;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
}
.report-modal__block{
  width: 469px;
  min-height: 177px;
  background: #FFFFFF;
  border-radius: 4px;
  position: relative;
  padding: 32px;
  margin-left: auto;
  margin-right: auto;
}
.report-modal__close{
  position: absolute;
  z-index: 9;
  right: -16px;
  top: -16px;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EB5757;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  i{
    color: #fff;
    font-size: 11px;
    width: 14px;
    height: 9px;
  }
}
.report-modal__error{
  color: #EB5757;
  text-align: center;
  padding: 10px 2px 0 57px;
  line-height: 20px;
  &.hidden{
    display: none;
  }
}
.report-modal__heading{
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
  padding-bottom: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ECECEC;
}
.report-modal__formalize{
  padding-left: 20px;
  &.hidden{
    display: none;
  }
}
.report-modal__choose{
  &.hidden{
    display: none;
  }
  .report-modal__delivery-chosen{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
}
.report-modal__steps{
  border-left: 1px solid #2278B2;
  padding-left: 35px;
}
.report-modal__step-title{
  display: flex;
  align-items: center;
  position: relative;
  height: 41px;
}
.report-modal__step-title-number{
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #2278B2;
  box-sizing: border-box;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #2278B2;
  border-radius: 50%;
  left: -56px;
  i{
    font-size: 15px;
    padding-top: 1px;
    &:before{
      width: auto;
    }
  }
}
.report-modal__step-title-text{
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
}
.report-modal__step-title-text-colored{
  color: #2278B2;
  margin-right: 3px;
}
.report-modal__step{
  .checkbox__wrapper{
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }
  .checkbox__text{
    font-size: 16px;
    line-height: 24px;
    color: #303030;
  }
  &.step1{
    .report-modal__step-content{
      display: flex;
      align-items: center;
    }
    .search-pretensions__calendar{
      max-width: unset;
      min-width: unset;
      width: 100%;
      &:nth-child(1){
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &.step2{
    .report-modal__step-content{
      display: flex;
      flex-direction: column;
    }
  }
}
.report-modal__step-content{
  padding-top: 12px;
  padding-bottom: 24px;
}

.report-modal__btn-choose{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #77d202;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  transition: 0.3s ease-in-out;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #77d202;
    color: #fff;
  }
}
.report-modal__btn-submit, .report-modal__delivery-submit{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #77d202;
  padding-left: 40px;
  padding-right: 40px;
  transition: 0.3s ease-in-out;
  width: 100%;

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #fff;
    color: #77d202;
  }
}

.report-modal__delivery-chosen{
  padding-top: 17px;
  border-top: 1px solid #ECECEC;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #303030;
}
.report-modal__delivery-chosen-val{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 8px;
  background: #2278B2;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-modal__delivery-search{
  position: relative;
  margin-bottom: 16px;
}
.report-modal__delivery-list-title{
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #2278B2;
  margin-bottom: 8px;
}
.report-modal__delivery-list-ul{
  padding-right: 20px;
  padding-bottom: 16px;
}
.report-modal__delivery-list-item{
  background: #F6FCFF;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 12px 16px;
}
.report-modal__delivery-footer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.report-modal__delivery-submit{
  width: auto;
  margin-bottom: 16px;
}
.report-modal__delivery-back{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #929292;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
