.pretension-modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  top: 0;
  pointer-events: none;
  opacity: 0;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0;
  }
  &.open{
    pointer-events: auto;
    opacity: 1;
    &:before{
      opacity: 0.32;
    }
  }
}
.pretension-modal__block{
  width: 609px;
  min-height: 177px;
  background: #FFFFFF;
  border-radius: 4px;
  position: relative;
  padding: 32px;
}
.pretension-modal__close{
  position: absolute;
  right: -12px;
  top: -12px;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EB5757;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  i{
    color: #fff;
    font-size: 11px;
    width: 14px;
    height: 9px;
  }
}
.pretension-modal__block-title{
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #ECECEC;
  font-size: 20px;
  line-height: 24px;
  color: #303030;
  margin-bottom: 16px;
  i{
    color: #FFB342;
    font-size: 30px;
    margin-right: 12px;
    display: flex;
    &:before{
      margin: 0;
    }
  }
}
.pretension-modal__block-content{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}
