@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?67338284');
  src: url('../font/fontello.eot?67338284#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?67338284') format('woff2'),
       url('../font/fontello.woff?67338284') format('woff'),
       url('../font/fontello.ttf?67338284') format('truetype'),
       url('../font/fontello.svg?67338284#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?67338284#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-analysis:before { content: '\e800'; } /* '' */
.icon-certificate:before { content: '\e801'; } /* '' */
.icon-back:before { content: '\e802'; } /* '' */
.icon-calendar:before { content: '\e803'; } /* '' */
.icon-tick:before { content: '\e804'; } /* '' */
.icon-cross:before { content: '\e805'; } /* '' */
.icon-document:before { content: '\e806'; } /* '' */
.icon-download:before { content: '\e807'; } /* '' */
.icon-hammer:before { content: '\e808'; } /* '' */
.icon-hourglass:before { content: '\e809'; } /* '' */
.icon-logout:before { content: '\e80a'; } /* '' */
.icon-mail:before { content: '\e80b'; } /* '' */
.icon-menu:before { content: '\e80c'; } /* '' */
.icon-phone:before { content: '\e80d'; } /* '' */
.icon-pin:before { content: '\e80e'; } /* '' */
.icon-search:before { content: '\e80f'; } /* '' */
.icon-arrow:before { content: '\e810'; } /* '' */
.icon-caution:before { content: '\e811'; } /* '' */
.icon-question-rounded:before { content: '\e812'; } /* '' */
.icon-garbage:before { content: '\e813'; } /* '' */
.icon-clipboard:before { content: '\e814'; } /* '' */
.icon-report:before { content: '\e815'; } /* '' */
.icon-chevron-left-bold:before { content: '\e816'; } /* '' */
.icon-filter-1:before { content: '\e81f'; } /* '' */
.icon-status-sent:before { content: '\e820'; } /* '' */
.icon-status-draft:before { content: '\e821'; } /* '' */
.icon-status-answered:before { content: '\e822'; } /* '' */
.icon-status-processing:before { content: '\e823'; } /* '' */
.icon-dialog-nose:before { content: '\e824'; } /* '' */
.icon-lock-1:before { content: '\e825'; } /* '' */
.icon-status-error:before { content: '\e826'; } /* '' */
.icon-lamp:before { content: '\e828'; } /* '' */
.icon-info:before { content: '\e84d'; } /* '' */
.icon-return-1:before { content: '\e85f'; } /* '' */
.icon-stop:before { content: '\e860'; } /* '' */
.icon-photo-camera:before { content: '\e879'; } /* '' */
.icon-draw:before { content: '\e87a'; } /* '' */
.icon-filter:before { content: '\e87b'; } /* '' */
.icon-chevron-down-mid:before { content: '\e87c'; } /* '' */
.icon-chevron-right-bold:before { content: '\e87d'; } /* '' */
.icon-status-done:before { content: '\e886'; } /* '' */
.icon-close-thin:before { content: '\e887'; } /* '' */
.icon-question:before { content: '\e888'; } /* '' */
