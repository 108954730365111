.certificate-content__documents-list {
  margin-top: 12px;
}

.certificate-item {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 32px;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #ececec;
  position: relative;
  cursor: pointer;

  &.certificate-item--active {
    border: 1px solid #2278b2;
    transition: 0.3s ease-in-out;

    &:after {
      transition: 0.3s ease-in-out;
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }
  }

  &:hover {
    border: 1px solid #2278b2;
    transition: 0.3s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    border: 10px solid transparent;
    border-left: 10px solid #2278b2;
    z-index: 2;
    opacity: 0;
    transition: 0s;
  }

  &:before {
    z-index: 3;
    content: '';
    position: absolute;
    display: block;
    right: 1px;
    top: 50%;
    transform: translate(100%, -50%);
    border: 10px solid transparent;
    border-left: 10px solid #fff;
    opacity: 0;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.certificate-item__button {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  text-align: left;
  cursor: pointer;
}

.certificate-item__number {
  width: 26%;
}

.certificate-item__number-text {
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #2278b2;
}

.certificate-item__date {
  width: 33%;
}

.certificate-item__price {
  width: 15%;
}

.certificate-item__date-text {
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}

.certificate-item__price-text {
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}

.certificate-item__download {
  margin-left: auto;
}

.certificate-item__download-link {
  font-size: 20px;
  color: #77d202;
  transition: 0.3s ease-in-out;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: darken(#77d202, 10%);
  }
}

.certificates-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 40px 40px;
  box-sizing: border-box;
  width: 89.6875%;
  background: #fff;
  position: relative;

  @media (max-width: 1499px) {
    padding: 24px;
  }

  img {
    max-width: calc(100% - 44px);
    height: auto !important;
    width: auto !important;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
  }

  a {
    color: #0c65a0;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.6;
    }
  }
}

.button-back{
  position: absolute;
  right: 41px;
  top: 17px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #77d202;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  transition: 0.3s ease-in-out;
  &:hover{
    background: #77d202;
    color: #fff;
  }
}

.certificates-info__headline {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  background: linear-gradient(45deg, #0c65a0 0%, #2d90d3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1499px) {
    font-size: 32px;
  }
}
