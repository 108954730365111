.login__content {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - 180px);

  .login__wrapper {
    width: 100%;
    max-width: 1800px;
    height: 100%;
    padding-left: 60px;
    padding-right: 60px;
    box-sizing: border-box;
    display: flex;
  }

  .login__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 65%;
    max-width: 65%;
    padding-bottom: 80px;
  }

  .login__headline {
    font-weight: bold;
    font-size: 68px;
    line-height: 114%;
    color: #2d90d3;
    padding-top: 80px;
    max-width: 700px;

    @media (max-width: 1399px) {
      font-size: 64px;
    }
  }

  .utp__list {
    display: grid;
    grid-gap: 70px;
    grid-template-columns: repeat(3, 1fr);
    padding-right: 90px;
    margin-top: 80px;
    margin-bottom: 40px;

    li {
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      color: #2278b2;

      &:after {
        width: 41px;
        height: 1px;
        background: #2278b2;
        content: '';
        display: block;
        margin-top: 12px;
      }
    }
  }

  .login__bottom-text {
    font-size: 16px;
    line-height: 150%;
    color: #737373;
    max-width: 900px;
    margin-top: auto;
  }

  .login__right {
    flex: 0 0 35%;
    max-width: 35%;
    padding: 48px 60px 60px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(170, 227, 255, 0.25);
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 80px;
    align-self: flex-start;
    position: relative;

    @media (max-width: 1499px) {
      padding: 45px;
    }

    @media (max-width: 1399px) {
      padding: 30px;
    }
  }

  .login__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &--active {
      opacity: 1;
      pointer-events: unset;
    }
  }

  .login__form-label {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 129%;
    color: #303030;
  }

  .login__error-text {
    font-style: italic;
    font-size: 12px;
    color: red;
    line-height: 112%;
    margin-top: 6px;
  }

  .login__form-input {
    font-size: 16px;
    line-height: 150%;
    color: #929292;
    height: 56px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 16px;
    margin-top: 8px;
    transition: 0.3s ease-in-out;

    &--error {
      border-color: red;
    }

    @media (max-width: 1399px) {
      font-size: 14px;
    }
  }

  .login__right-headline {
    font-weight: 500;
    font-size: 48px;
    line-height: 117%;
    color: #2d90d3;

    @media (max-width: 1399px) {
      font-size: 38px;
    }
  }

  .login__right-text {
    font-size: 14px;
    line-height: 129%;
    color: #929292;
    margin-top: 16px;
  }

  .login__remember {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .login__submit-form {
    background: #77d202;
    border-radius: 4px;
    height: 56px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 112%;
    border: none;
    margin-top: 24px;
    color: #ffffff;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
