.pager {
  display: flex;
  align-items: center;

  .next,
  .previous {
    max-height: 32px;
  }
}

.pager__arrow-prev,
.pager__arrow-next {
  font-size: 0;
  color: #029adf;
  cursor: pointer;

  &:before {
    display: flex;
    font-size: 6px;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    transform: rotate(90deg);
  }
  &[aria-disabled="true"]{
    opacity: 0.3;
    cursor: default;
  }
}

.pager__arrow-next {
  &:before {
    transform: rotate(-90deg);
  }
}

.pager__button {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  transition: 0.3s ease-in-out;

  &:hover {
    background: #fbfbfb;
    color: lighten(#303030, 50%);
  }

  &.pager__button--active {
    background: #2278b2;
    border-radius: 2px;
    color: #fff;
    cursor: default;
    pointer-events: none;
  }
}
