@import 'delivery';
@import 'personalArea';

.header {
  height: 120px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: sticky;
  top: 0;
  z-index: 20;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #070a0c;
    opacity: 0.6;
    z-index: 1;
  }

  .header__container {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  .header__logo-wrapper {
    display: flex !important;
    align-items: center;

    @media (max-width: 1499px) {
      width: 250px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .header__email-wrapper {
    margin-left: 67px;
    margin-right: auto;
    margin-top: 10px;

    @media (max-width: 1499px) {
      margin-left: 30px;
    }

    i {
      font-size: 24px;
      transition: 0.3s ease-in-out;
      color: #fff;
    }
  }

  .header__email-link {
    transition: 0.3s ease-in-out;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    color: #fff;
    margin-left: 13px;

    @media (max-width: 1499px) {
      font-size: 20px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .header__logo-link {
    img {
      transition: 0.3s ease-in-out;
      height: auto;

      @media (max-width: 1599px) {
        max-width: 300px;
      }
    }

    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }

  .language-change__wrapper {
  }

  .language-change__button {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    border: none;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s ease;

    &--active {
      border: 1px solid #ffffff !important;
    }

    &:hover {
      opacity: 0.6;
    }

    &:first-child {
      margin-right: 10px;
    }
  }

  .header__logout-link {
    margin-left: 55px;
    color: #fff;

    @media (max-width: 1499px) {
      margin-left: 30px;
    }

    &:hover {
      color: darken(#fff, 40%);
    }

    i {
      font-size: 25.5px;
      transition: 0.3s ease-in-out;
    }
  }
}
