.pretensions-list{
  width: 100%;

  .col-number{
    width: 8%;
  }
  .col-date{
    width: 8%;
  }
  .col-facture{
    width: 7%;
  }
  .col-photo{
    width: 6%;
    text-align: center;
  }
  .col-edits{
    width: 7%;
    text-align: center;
  }
  .col-answer{
    width: 24%;
    padding-left: 2%;
  }
  .col-sum{
    width: 10%;
  }
  .col-updated{
    width: 10%;
  }
  .col-status{
    width: 14%;
  }
  .col-btn{
    width: 6%;
    display: flex;
    justify-content: flex-end;
  }
}
.pretensions-list__header{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 0 32px;
  box-sizing: border-box;
}
.pretensions-list__header-item{
  flex-shrink: 0;
  button.pretensions-list__header-item-text{
    cursor: pointer;
    transition: .3s ease;
    &:hover{
      opacity: .6;
    }
  }
}

.pretensions-list__header-item-text{
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #929292;
  background: transparent;
  border: none;
  cursor: default;
  padding: 0;
}

.pretensions-list__list{
  padding-top: 16px;
}

.pretensions-list__bottom-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
}
