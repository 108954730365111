.count-list__text {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.count-list__button {
  margin-top: 5px;
  display: inline-flex;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 79px;
  height: 32px;
  border: none;
  background: #fbfbfb;
  border-radius: 50px;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: darken(#fbfbfb, 20%);
  }

  &:last-child {
    margin-right: 0;
  }

  &.count-list__button--active {
    background: #2278b2;
    color: #ffffff;
    cursor: default;

    &:hover {
      background: #2278b2;
    }
  }
}
