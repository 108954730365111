.pretension-view-list{
  width: 100%;
  padding-top: 30px;


  .col-name{
    width: 31%;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-series{
    width: 9%;
  }
  .col-amount{
    width: 9%;
    text-align: center;
  }
  .col-price{
    width: 10%;
    text-align: center;
  }
  .col-lack{
    width: 10%;
    text-align: center;
  }
  .col-broken{
    width: 8%;
    text-align: center;
  }
  .col-defect{
    width: 8%;
    text-align: center;
  }
  .col-surplus{
    width: 9%;
    text-align: center;
  }
}
.pretension-view-list__header{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}
.pretension-view-list__header-item{
  flex-shrink: 0;
  button.pretension-view-list__header-item-text{
    cursor: pointer;
    transition: .3s ease;
    &:hover{
      opacity: .6;
    }
  }
}

.pretension-view-list__header-item-text{
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #929292;
  background: transparent;
  border: none;
  cursor: default;
  padding: 0;
}

.pretension-view-list__list{
  padding-top: 16px;
  padding-bottom: 24px;
}

.pretension-view-item{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-bottom: none;
  box-sizing: border-box;
  width: 100%;
  padding: 28px 32px;
  .pretension-view-item__static-content{
    width: 100%;
    display: flex;
    align-items: center;
  }
  &:first-child{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child{
    border-bottom: 1px solid #ECECEC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &.extended{
    padding-bottom: 24px;
  }
}
.pretension-view-item__dynamic-content{
  overflow: hidden;
  max-height: 84px;
  transition: max-height 0.2s;
  &.wrapped{
    max-height: 4px;
  }
  .dynamic-content__title{
    padding-top: 12px;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: #2278B2;
  }
  .dynamic-content__list{
    display: flex;
    background: #F4FBFF;
    padding: 12px 0;
    .col-name{
      padding-left: 16px;

    }
  }
}

.pretension-view-item__col{
  flex-shrink: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}

.pretension-view-list__footer{
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid #ECECEC;
}

.pretension-view-item__pan{
  display: flex;
  align-items: center;
  & > i{
    color: #2278B2;
    font-size: 18px;
    margin-right: 17px;
  }
}
.pretension-view-item__button-wrap{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background: #2278B2;
  transform: rotate(-90deg);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s;
  i{
    font-size: 10px;
    color: #fff;
  }
  &.closed{
    transform: rotate(90deg);
  }
}
