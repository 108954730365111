.visually-hidden {
  position: absolute !important;
  visibility: hidden !important;
  pointer-events: none !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(100%) !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  text-indent: -9999px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}
button, input, optgroup, select, textarea {
  font-family: 'Roboto', sans-serif;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video{
  font-family: 'Roboto', sans-serif;
}
*,
*:active,
*:focus {
  outline: none !important;
}

.container {
  max-width: 1920px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
}

.content-container {
  display: flex;
  max-width: 1980px;
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - 180px);

  @media (max-width: 1399px) {
    min-height: calc(100vh - 100px);
  }
}

.App {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 25% 100%;
  position: relative;
}

.input-wrapper{
  position: relative;
}

.react-datepicker__input-container input,
.react-datepicker-ignore-onclickoutside {
  background: transparent;
  width: 100px;
  border: none;
}

.main_preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;

  &--active {
    opacity: 1;
    pointer-events: unset;
  }
}

div{
  box-sizing: border-box;
}
