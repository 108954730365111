.pretension-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 40px 40px;
  box-sizing: border-box;
  width: 89.6875%;
  background: #fff;

  @media (max-width: 1499px) {
    padding: 24px;
  }
}
.pretension-view__btn-back{
  background: transparent;
  border: none;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  i{
    font-size: 10px;
    display: flex;
    padding-bottom: 1px;
    margin-right: 4px;
    color: #0c65a0;
  }
}
.pretension-view__btn-back-text{
  background: linear-gradient(45deg, #0c65a0 0%, #2d90d3 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pretension-view__headline-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pretension-view__headline {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  background: linear-gradient(45deg, #0c65a0 0%, #2d90d3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 20px;
  margin-bottom: 24px;
  @media (max-width: 1499px) {
    font-size: 32px;
  }
}

.pretension-view__body{
  display: flex;
  justify-content: space-between;
}
.pretension-view__aside{
  width: 482px;
  flex-shrink: 0;
}
.pretension-view__content{
  width: 100%;
  padding-left: 48px;
  .pretension-search-products{
    .pretension-search-factures__form{
      display: none;
    }
  }
}

.pretension-view__content-title{
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
  padding-bottom: 16px;
}

.pretension-view__info{
  margin-bottom: 24px;
}

.pretension-view__info-title{
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid #ECECEC;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
}
.pretension-view__info-item{
  background: #F4FBFF;
  border-radius: 4px;
  margin-bottom: 4px;
  overflow: hidden;
  display: flex;
}
.pretension-view__info-col-left{
  width: 50%;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #929292;
}
.pretension-view__info-col-right{
  width: 50%;
  padding: 14px 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  .pretension-status{
    padding-left: 26px;
    i:before{
      font-size: 18px;
    }
  }
}
.pretension-view__footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid #ECECEC;
  .pretension-view__btn-back{
    margin-bottom: 0;
  }
}
.pretension-view__button-save{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #77d202;
  border-radius: 4px;
  min-width: 110px;
  height: 46px;
  border: 1px solid #77d202;
  color: #fff;
  cursor: pointer;
  transition: .3s ease-in-out;
  white-space: nowrap;
  padding: 4px 25px;
  &:hover{
    background: #fff;
    color: #77d202;
  }
  i{
    font-size: 22px;
    margin-right: 6px;
  }
  span{
    font-size: 16px;
    line-height: 1.5;
  }
}
.pretension-view__button-cancel{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 4px;
  min-width: 214px;
  height: 46px;
  border: 1px solid #ECECEC;
  color: #929292;
  cursor: pointer;
  transition: .3s ease-in-out;
  white-space: nowrap;
  padding: 4px 25px;
  margin-left: auto;
  margin-right: 16px;
  &:hover{
    border-color: #929292;
    background: #929292;
    color: #fff;
  }
  i{
    font-size: 22px;
    margin-right: 6px;
  }
  span{
    font-size: 16px;
    line-height: 1.5;
  }
}
