.pretension-formalize__search-list{
  padding-top: 24px;
  .col-name{
    width: 33%;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-series{
    width: 7%;
  }
  .col-amount{
    width: 7%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .col-price{
    width: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .col-caution{
    width: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .col-lack{
    width: 9%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .col-broken{
    width: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .col-defect{
    width: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .col-overage{
    width: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .col-indicate{
    width: 2%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
.pretension-formalize__top{
  position: relative;
}
.pretension-formalize__bottom{
  position: relative;
}
.pretension-formalize__heading{
  padding: 24px 0;
  border-bottom: 1px solid #ECECEC;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
}

.pretension-formalize__details{
  padding: 24px 0;
  border-bottom: 1px solid #ECECEC;
  display: flex;
  &.pretension-view{
    flex-direction: column;
    padding: 0;
    width: 100%;
    .details-block{
      padding: 24px 0;
      border-top: 1px solid #ECECEC;
    }
  }
  .details-block{
    &.comment{
      width: 25vw;
      margin-right: 48px;
      textarea{
        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        padding: 16px;
      }
    }
  }
  .details-block__heading{
    display: flex;
    align-items: baseline;
    padding-bottom: 16px;
  }
  .details-block__comment{
    textarea{
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 4px;
      height: 154px;
      resize: none;
    }
  }
  .details-block__title{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
    margin-right: 11px;
  }
  .details-block__description{
    font-size: 16px;
    line-height: 20px;
    color: #929292;
  }
  .images-wrapper{
    display: flex;
  }
}

.pretension-formalize__list-header{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 0 32px;
  box-sizing: border-box;
}
.pretension-formalize__header-item{
  flex-shrink: 0;
  button.pretension-formalize__header-item-text{
    cursor: pointer;
    transition: .3s ease;
    &:hover{
      opacity: .6;
    }
  }
  &.col-indicate{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .fill-button{
      position: absolute;
      margin-left: 0;
      i{
        color: #EB5757;
      }
    }
  }
}

.fill-button{
  margin-left: 5px;
  position: relative;
  &:hover{
    .fill-button__info{
      opacity: 1;
    }
  }
  button{
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    transform: translateY(-2px);
    i{
      color: #77D202;
    }
  }
}
.fill-button__info{
  font-family: Roboto;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  background: #FFFFFF;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  color: #303030;
  white-space: nowrap;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 10;
}

.pretension-formalize__header-item-text{
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #929292;
  background: transparent;
  border: none;
  cursor: default;
  padding: 0;
}

.pretension-formalize__list{
  padding-top: 16px;
  position: relative;
}


.pretension-product__item{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-bottom: none;
  box-sizing: border-box;
  transition: .3s;
  display: flex;
  align-items: center;
  padding: 0 0 0 32px;
  height: 80px;
  &.hidden{
    display: none;
  }
  &:first-child{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child{
    border-bottom: 1px solid #ECECEC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.pretension-product__item-col{
  flex-shrink: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  input{
    width: 80px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
  }
  &.col-indicate{
    i{
      display: none;
      color: #14BD25;
      &.active{
        display: block;
      }
    }
    .pretension-product__item-button-remove{
      i{
        display: block;
        color: #929292;
        font-size: 10px;
      }
    }
  }
}
.pretension-product__item-button-remove{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FAFAFA;
  padding: 0;
  border: none;
  i{
    color: #929292;
  }
  &:hover{
    cursor: pointer;
  }
}

.invalid-badge{
  position: relative;
  i{
    color: #EB5757;
  }
  &:hover{
    .invalid-badge__tick{
      opacity: 1;
    }
  }
}
.invalid-badge__tick{
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 102px;
  height: 52px;
  background: #EB5757;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  bottom: 0;
  left: 50%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, 100%);
}
.pretension-product__item-button-remove{
  i{
    display: block;
  }
}

.btn-pretension-create{
  opacity: 0;
  transition: .3s;
}

.pretension-formalize__bottom-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  margin: 24px 0;
  border-top: 1px solid #ECECEC;
  border-bottom: 1px solid #ECECEC;
  position: relative;
  .pager{
    position: absolute;
    right: 50%;
    transform: translateX(-50%);
  }
}
.pretension-formalize__bottom-nav-paginate{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 100px;
  width: 100%;
  &.hidden{
    display: none;
  }
}
.pretension-formalize__delete{
  margin-right: 16px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #c49e9e;
  border: 1px solid #c49e9e;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s ease-in-out;
  flex-shrink: 0;

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    color: #EB5757;
    border: 1px solid #EB5757;
  }
}
.pretension-formalize__submit{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #77d202;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s ease-in-out;
  flex-shrink: 0;

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #77d202;
    color: #fff;
  }

  &.btn-draft{
    margin-left: auto;
    margin-right: 16px;
  }

  &.btn-send{
    background: #77d202;
    color: #fff;
    &:hover{
      background: #fff;
      color: #77d202;
    }
  }
}
