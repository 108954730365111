.checkbox__wrapper {
  display: inline-flex;
  cursor: pointer;

  .checkbox__input {
    display: none;

    &:checked {
      & + .checkbox__tick-wrapper {
        border: 1px solid #2278b2;
        background: #2278b2;
      }
    }
  }

  .checkbox__tick-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #929292;
    box-sizing: border-box;
    border-radius: 2px;
    transition: 0.3s ease-in-out;
    background: #fff;

    i {
      font-size: 9px;
      color: #fff;
      width: 15px;
      display: flex;
    }
  }

  .checkbox__text {
    font-size: 16px;
    line-height: 150%;
    color: #2278b2;
    padding-left: 15px;
  }
}
