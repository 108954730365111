.navigation {
  width: 8.5%;
  min-width: 145px;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.navigation__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
}

.navigation__item {
  width: 120px;
  height: 120px;
  margin-bottom: 8px;
  background: linear-gradient(45deg, #0c65a0 0%, #2d90d3 100%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(12, 101, 160, 0);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    right: -1px;
    top: 50%;
    transform: translate(100%, -50%);
    border: 10px solid transparent;
    border-left: 10px solid #0c65a0;
    z-index: 2;
    opacity: 0;
  }

  &:before {
    z-index: 3;
    content: '';
    position: absolute;
    display: block;
    right: 2px;
    top: 50%;
    transform: translate(100%, -50%);
    border: 10px solid transparent;
    border-left: 10px solid #fff;
    opacity: 0;
  }

  &:hover {
    border: 2px solid #0c65a0;
    background: #fff;

    .navigation__item-icon {
      color: #0c65a0;
    }

    .navigation__item-text {
      color: #303030;
    }
  }

  &.navigation__item-active {
    border: 2px solid #0c65a0;
    background: #fff;

    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }

    .navigation__item-icon {
      color: #0c65a0;
    }

    .navigation__item-text {
      color: #303030;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.navigation__item-notice{
  position: absolute;
  width: 25px;
  height: 25px;
  background: #ffa000;
  color: #fff;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  &.hidden{
    display: none;
  }
}

.navigation__item-icon {
  color: #fff;
  font-size: 35.5px;
  margin-bottom: 12px;
  transition: 0.5s ease-in-out;
}

.navigation__item-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  transition: 0.5s ease-in-out;
  text-align: center;
}
