.images-upload{
  display: flex;
}

.images-list{
  display: flex;
}

.image-item{
  width: 172px;
  height: 154px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-right: 16px;
  .image-item__open{
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.uploaded{
    .image-item__remove{
      display: none;
    }
    &:hover{
      .image-item__remove{
        display: flex;
      }
    }
  }
}

.image-item__remove{
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 12px;
  background: #EB5757;
  cursor: pointer;
  border: none;
  padding: 0;
  i{
    color: #FFF;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    padding-top: 2px;
  }
}

.images-input{
  input{
    display: none;
  }
  label{
    opacity: 0.56;
    width: 172px;
    height: 154px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #77D202;
    box-sizing: border-box;
    border-radius: 4px;
    transition: opacity 0.3s;
    cursor: pointer;
    &:hover{
      opacity: 1;
    }
  }
  i{
    color: #77D202;
    margin-bottom: 12px;
    font-size: 40px;
  }
  span{
    font-size: 16px;
    line-height: 24px;
    color: #303030;
  }
}
