.certificates-right {
  padding: 30px 0 0 32px;
  width: 50%;
  position: relative;
  .certificates-right__wrapper{
    display: block;
  }
}

.certificates-right__wrapper {
  padding: 32px 0 32px 35px;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(34, 120, 178, 0.14);
  border-radius: 4px;
  position: relative;

}
.certificates-right__wrapper-empty{
  padding: 32px 0 32px 35px;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(34, 120, 178, 0.14);
  border-radius: 4px;
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #303030;
}

.certificates-right__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: #303030;
  padding-right: 35px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #ececec;
    margin-top: 19px;
  }
}

.certificates-right__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-right: 35px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #ececec;
    margin-top: 17px;
  }
}

.certificates-right__choose {
  display: flex;
  align-items: center;
}

.certificates-right__choose-text {
  font-size: 16px;
  line-height: 24px;
  color: #929292;
}

.certificates-right__choose-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  width: 25px;
  height: 25px;
  background: #2278b2;
  border-radius: 50%;
  margin-left: 8px;
}

.certificates-right__header-download {
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    .certificates-right__header-download-text,
    .certificates-right__header-download-icon {
      color: darken(#77d202, 10%);
    }
  }
}

.certificates-right__header-download-text {
  font-size: 16px;
  line-height: 24px;
  color: #77d202;
  transition: 0.3s ease-in-out;
}

.certificates-right__header-download-icon {
  font-size: 20px;
  margin-left: 4px;
  color: #77d202;
  transition: 0.3s ease-in-out;
}

.certificates-right__list-header {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 12px;
  padding-right: 35px;
}

.certificates-right__list-header-checkbox {
  padding-left: 33px;
  padding-right: 24px;
}

.certificates-right__list-header-name {
  width: 32%;
}

.certificates-right__list-header-series {
  width: 12.6%;
}

.certificates-right__list-header-certificate {
  flex-grow: 1;
}

.certificates-right__list-header-name-text,
.certificates-right__list-header-series-text,
.certificates-right__list-header-certificate-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #929292;
}

.certificates-right__footer {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  padding-right: 35px;
}

.certificates-right__list {
  padding-right: 20px;
}

.certificates-right__list-wrapper {
  padding-right: 14px;
}

.certificates-right__footer-choose {
  display: flex;
  align-items: center;
}

.certificates-right__footer-choose-text {
  font-size: 16px;
  line-height: 24px;
  color: #929292;
}

.certificates-right__footer-choose-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  background: #2278b2;
  width: 25px;
  height: 25px;
  color: #ffffff;
  margin-left: 8px;
  border-radius: 50%;
}

.certificates-right__footer-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #77d202;
  border-radius: 4px;
  width: 176px;
  cursor: pointer;
  height: 48px;
  transition: 0.3s ease-in-out;
  border: 1px solid #77d202;

  &:hover {
    background: #fff;

    .certificates-right__footer-link-icon,
    .certificates-right__footer-link-text {
      color: #77d202;
    }
  }
}

.certificates-right__footer-link-icon {
  font-size: 20px;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.certificates-right__footer-link-text {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
}

.certificates-right__list-item {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 32px;
  min-height: 86px;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.certificates-right__list-item-checkbox {
  padding-right: 24px;
}

.certificates-right__list-item-name {
  width: 35%;
  padding-right: 10px;
  box-sizing: border-box;
}

.certificates-right__list-item-name-text,
.certificates-right__list-item-series-text,
.certificates-right__list-item-certificate-text {
  font-size: 14px;
  line-height: 21px;
  color: #303030;
}

.certificates-right__list-item-series {
  width: 14.1%;
}

.certificates-right__list-item-certificate {
  flex-grow: 1;
}

.certificates-right__list-item-link {
  font-size: 20px;
  color: #77d202;
  transition: 0.3s ease-in-out;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: darken(#77d202, 10%);
  }
}
