.personal-area {
  width: 255px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-right: 6px;
  cursor: pointer;

  @media (max-width: 1399px) {
    width: 200px;
  }

  &:hover {
    .personal-area__user-name {
      color: darken(#fff, 40%);
    }
  }
}

.personal-area__text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.personal-area__headline {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  transition: 0.3s ease-in-out;
}

.personal-area__user-name {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  transition: 0.3s ease-in-out;
}
