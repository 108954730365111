.search-pretensions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  padding-bottom: 22px;
  width: 100%;
  border-bottom: 1px solid #ececec;
}

.search-pretensions__headline {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #303030;
  margin-right: 15px;

  @media (max-width: 1599px) {
    font-size: 16px;
    margin-right: 5px;
  }
}

.search-pretensions__form {
  display: flex;
  align-items: center;
  margin-right: auto;
  flex-shrink: 0;
  padding-right: 30px;
}
.search-pretensions__form-wrapper {
  display: flex;
  align-items: center;
}

.search-pretensions__input-product-wrapper {
  flex: 1;
  position: relative;
  margin-right: 24px;
  &:hover{
    .float-info{
      opacity: 1;
    }
  }
  .float-info{
    position: absolute;
    bottom: -10px;
    left: 0;
    transform: translateY(100%);
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    pointer-events: none;
    opacity: 0;
    padding: 10px 15px;
    transition: 0.1s;
    border-radius: 8px;
    max-width: 280px;
    line-height: 22px;
    z-index: 100;
  }
}

.search-pretensions__button-reset-facture {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-pretensions__button-reset {
  background: transparent;
  color: #c0c0c0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border: none;
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.search-pretensions__input-product {
  width: 100%;
  height: 46px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #303030;
  padding: 0 16px;
  box-sizing: border-box;

  &:disabled {
    background: #ebebeb;
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #303030;

    @media (max-width: 1599px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1599px) {
    font-size: 14px;
    margin-right: 5px;
    padding: 0 10px;
  }
}

.search-pretensions__input-product-error {
  border-color: red !important;
}

.input-wrapper.series{
  margin-right: 15px;
}

.search-pretensions__input {
  max-width: 16vw;
  min-width: 307px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  color: #303030;
  margin-left: 5px;
  padding: 0 16px;
  transition: 0.3s ease;
  box-sizing: border-box;
  text-overflow: ellipsis;

  &:disabled {
    background: #ebebeb;
  }

  @media (max-width: 1599px) {
    font-size: 14px;
    margin-right: 5px;
    padding: 0 10px;
    min-width: 260px;
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #303030;

    @media (max-width: 1599px) {
      font-size: 14px;
    }
  }
}

.search-pretensions__calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 190px;
  min-width: 150px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 0 16px 0 0;
  margin-right: 15px;
  cursor: pointer;
  margin-left: 15px;

  & > .react-datepicker-wrapper {
    box-sizing: border-box;

    input {
      height: 46px;
      box-sizing: border-box;
      padding-left: 16px;
    }
  }

  &:hover {
    .search-pretensions__calendar-text {
      color: lighten(#303030, 30%);
    }

    .search-pretensions__calendar-icon {
      color: darken(#c0c0c0, 30%);
    }
  }
}

.search-pretensions__calendar-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #303030;
  transition: 0.3s ease-in-out;
}

.search-pretensions__calendar-icon {
  color: #c0c0c0;
  font-size: 20px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
}

.search-pretensions__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #77d202;
  border-radius: 4px;
  width: 7.8125vw;
  max-width: 150px;
  min-width: 110px;
  height: 46px;
  border: 1px solid #77d202;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  i{
    font-size: 22px;
    margin-right: 6px;
  }

  &:hover {
    background: #fff;
    color: #77d202;
  }
}

.search-pretensions__form-submit-icon {
  font-size: 22px;
  margin-right: 6px;

  @media (max-width: 1599px) {
    font-size: 18px;
    margin-right: 3px;
  }
}

.search-pretensions__form-submit-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

.search-pretensions__form-facture {
  display: flex;
  align-items: center;
}
.search-pretensions__buttons-wrap{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-bottom: -5px;
}
.btn-report{
  flex-shrink: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #77d202;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s ease-in-out;
  width: 100vw;
  max-width: 250px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 5px;

  i{
    font-size: 22px;
    margin-right: 6px;
  }

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #77d202;
    color: #fff;
  }
}
.btn-pretensy-create{
  flex-shrink: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #77d202;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s ease-in-out;
  width: 100vw;
  max-width: 250px;
  box-sizing: border-box;
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 5px;

  i{
    font-size: 22px;
    margin-right: 6px;
  }

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #fff;
    color: #77d202;
  }
}
