.pretensions-facture__search-list{
  padding-top: 24px;
  .col-facture{
    width: 30%;
  }
  .col-date{
    width: 30%;
  }
}
.pretensions-facture__list-header{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 32px 0 32px;
  box-sizing: border-box;
}
.pretensions-facture__header-item{
  flex-shrink: 0;
  button.pretensions-facture__header-item-text{
    cursor: pointer;
    transition: .3s ease;
    &:hover{
      opacity: .6;
    }
  }
}

.pretensions-facture__header-item-text{
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #929292;
  background: transparent;
  border: none;
  cursor: default;
  padding: 0;
}

.pretensions-facture__list{
  padding-top: 16px;
}


.pretensions-facture__item{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-bottom: none;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px 0 32px;
  height: 80px;
  transition: .3s;
  &:first-child{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child{
    border-bottom: 1px solid #ECECEC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:hover{
    border-color: #2278B2;
    & + .pretensions-facture__item{
      border-top-color: #2278B2;
    }
    .btn-pretension-create{
      opacity: 1;
    }
  }
}

.pretension-item__col{
  flex-shrink: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;

}

.pretensions-facture__content {
  &.col-content{
    margin-left: auto;
  }
}

.btn-pretension-create{
  opacity: 0;
  background: #77D202;
  border-radius: 4px;
  padding: 10px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  height: 46px;
  border: 1px solid #77d202;
  cursor: pointer;
  transition: .3s ease-in-out;
  i{
    font-size: 20px;
    margin-right: 4px;
  }
  &:hover{
    background: #fff;
    color: #77d202;
  }
}

.pretensions-facture__bottom-nav{
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.pretension-search-factures{
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-bottom: 22px;
  border-bottom: 1px solid #ececec;
}
.pretension-search-factures__headline{
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #303030;
  margin-right: 20px;
}
.pretension-search-factures__form{
  display: flex;
  align-items: center;
  flex: 1 1;
}
.pretension-search-factures__form-wrapper{
  flex: 1 1;
  padding-right: 15px;
  position: relative;
}
.pretension-search-factures__submit{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #77d202;
  border-radius: 4px;
  width: 7.8125vw;
  max-width: 150px;
  min-width: 110px;
  height: 46px;
  border: 1px solid #77d202;
  color: #fff;
  cursor: pointer;
  transition: .3s ease-in-out;
  &:hover{
    background: #fff;
    color: #77d202;
  }
  i{
    font-size: 22px;
    margin-right: 6px;
  }
  span{
    font-size: 16px;
    line-height: 1.5;
  }
}
.search-certificate__input-product-wrapper{
  flex: 1 1;
  position: relative;
}
.pretension-search-factures__input{
  width: 100%;
  height: 46px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #303030;
  padding: 0 16px;
  box-sizing: border-box;
}
