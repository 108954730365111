.certificates {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 40px 40px;
  box-sizing: border-box;
  width: 89.6875%;
  background: #fff;

  @media (max-width: 1499px) {
    padding: 24px;
  }
}

.certificates__headline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.certificates__headline {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  background: linear-gradient(45deg, #0c65a0 0%, #2d90d3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 20px;
  @media (max-width: 1499px) {
    font-size: 32px;
  }
}

.certificates__button-instructions {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #77d202;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  transition: 0.3s ease-in-out;

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #77d202;
    color: #fff;
  }
}

.certificates__content {
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
}
.certificates-right:not(.empty){
  margin-left: auto;
}

.certificates__content-search-error {
  background: #F4FBFF;
  border-radius: 4px;
  padding: 8px 8px 8px 44px;
  margin-bottom: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  width: 100%;
  max-width: 650px;
  position: relative;
  i{
    color: #2278B2;
    margin-right: 8px;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    left: 9px;
  }
}

.certificates__content-search-error-button {
  color: #2278b2;
  cursor: pointer;
  transition: 0.3s ease;
  background: transparent;
  padding-left: 4px;
  border: none;

  &:hover {
    color: #4182b0;
  }
}

.certificate-content__documents {
  width: 50%;
  min-height: 400px;
  position: relative;
}

.certificate-content__documents-header {
  display: flex;
  align-items: center;
  padding: 0 32px;
}

.certificate-content__documents-header-date-text,
.certificate-content__documents-header-document-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #929292;
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  i {
    width: 16px;
    font-size: 6px;
    padding-left: 5px;
    padding-right: 5px;

    &:before {
      justify-content: center;
      align-items: center;
      display: flex;
      margin-right: 0;
      width: 16px;
    }
  }
  .up {
    &:before {
      transform: rotate(-180deg);
    }
  }
}

.certificate-content__documents-header-document-container {
  width: 26%;
}

.certificate-content__documents-header-date-container {
  width: 33%;
}

.certificate-content__documents-header-document-text, .certificate-content__documents-header-date-text{
  background: transparent;
  border: none;
  padding: 0;
}

.certificate-content__bottom-nav {
  display: flex;
  align-items: flex-end;
  padding-top: 16px;
  justify-content: space-between;
}
