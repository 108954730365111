.delivery__wrapper {
  max-width: 379px;
  width: 100%;
  margin-left: auto;
  position: relative;
  margin-right: 30px;

  @media (max-width: 1599px) {
    max-width: 300px;
  }
}

.delivery__pop-up {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 72px;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.delivery__pop-up-open {
  transition: 0.3s ease-in-out;
  max-height: 1000%;
  overflow: visible;
  opacity: 1;
}

.delivery__input-wrapper {
  position: relative;
}

.delivery__input {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  padding-left: 16px;
  font-size: 16px;
  line-height: 19px;
  color: #929292;

  @media (max-width: 1599px) {
    font-size: 14px;
  }

  &::placeholder {
    font-size: 16px;
    color: #929292;

    @media (max-width: 1599px) {
      font-size: 14px;
    }
  }
}

.delivery__item {
  background: #f6fcff;
  border-radius: 4px;
  padding: 9px 12px;
  width: 100%;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 12px;
  line-height: 133%;
  color: #9c9c9c;
  text-align: left;
  border: none;
}

.delivery__item-active {
  background: #b6ebff;
}

.delivery__item-top {
  font-weight: 500;
  font-size: 14px;
  line-height: 129%;
  color: #303030;
}

.delivery__text-list {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
  color: #2278b2;
  margin-top: 16px;
  margin-bottom: 8px;
}

.delivery__input-submit {
  position: absolute;
  background: transparent;
  border: none;
  font-size: 26px;
  right: 3px;
  top: 9px;
  color: #2278b2;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.delivery__select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(4px);
  padding: 14px 20px 14px 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
    backdrop-filter: blur(6px);

    .delivery__select-city {
      .delivery__select-city-text {
        color: darken(#fff, 20%);
      }

      i {
        color: darken(#fff, 20%);
      }
    }
  }
}

.delivery__pin {
  color: #fff;
  font-size: 30px;
  margin-right: 3px;
}

.delivery__text-wrapper {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-grow: 1;
}

.delivery__select-headline {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: left;
  color: #fff;
  margin-bottom: 4px;
}

.delivery__select-city {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  i {
    font-size: 6px;
    transition: 0.3s ease-in-out;
  }
}

.delivery__select-city-text {
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  color: #fff;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 18px;

  @media (max-width: 1499px) {
    font-size: 14px;
  }
}
