.step-nav{
  background: #F4FBFF;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 16px;
  height: 48px;
  position: relative;
  font-family: Roboto;
}
.step-nav__btn-close{
  margin-left: auto;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2278B2;
  i{
    margin-right: 7px;
  }
}
.step-nav__btn{
  position: absolute;
  background: #E7F5FF;
  border: none;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  &:after{
    position: absolute;
    right: -32px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    content: "";
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 16px solid #E7F5FF;
    border-right: 16px solid transparent;
  }
  &.active{
    background: #2278B2;
    cursor: default!important;
    &:after{
      border-left: 16px solid #2278B2;
    }
    .number{
      background: #fff;
      color: #2278B2;
    }
    span{
      color: #fff;
    }
  }
  &.step1{
    z-index: 10;
    width: 219px;
    position: relative;
    cursor: pointer;
    padding-left: 16px;
  }
  &.step2{
    z-index: 9;
    width: 500px;
    padding-left: 250px;
  }
  .number{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #2278B2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    margin-right: 12px;
  }
  span{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
  }
}
