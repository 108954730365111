.pretension-chat{
  width: 100%;
  background: #F4FBFF;
  border-radius: 4px;
  margin-bottom: 28px;
  padding: 20px 24px 24px;
  display: flex;
  flex-direction: column;
}
.pretension-chat__title{
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #2278B2;
  margin-bottom: 12px;
}
.pretension-chat__list{
  background: #FFFFFF;
  box-shadow: 1px 1px 8px rgba(9, 60, 94, 0.16);
  border-radius: 4px;
  padding: 16px 0 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 374px;
  overflow: auto;
  margin-bottom: 15px;
  min-height: 200px;
  .rcs-custom-scroll{
    width: 100%;
  }
}
.pretension-chat__list-inner{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 16px;
}
.chat-message{
  width: 327px;
  margin-bottom: 16px;
  &:last-child{
    margin-bottom: 0;
  }
  &.client{
    align-self: flex-start;
    .chat-message__footer{
      text-align: right;
    }
    .chat-message__content{
      i{
        left: 32px;
        right: auto;
      }
    }
  }
  &.isnew{
    .chat-message__content {
      background: #2278B2;
      i{
        color: #2278B2;
      }
    }
    .chat-message__name{
      color: #fff;
    }
    .chat-message__text{
      color: #fff;
    }
  }
}

.chat-message__content{
  background: #F4FBFF;
  border-radius: 4px;
  padding: 12px 16px;
  position: relative;
  z-index: 2;
  i{
    position: absolute;
    right: 32px;
    left: auto;
    bottom: 1px;
    transform: translateY(100%);
    z-index: -1;
    color: #F4FBFF;
  }
}
.chat-message__name{
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2278B2;
  margin-bottom: 4px;
}
.chat-message__text{
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #303030;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.chat-message__footer{
  font-size: 12px;
  line-height: 16px;
  color: #929292;
  padding-top: 5px;
}
.pretension-chat__conclusion{
  display: flex;
  align-items: stretch;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 17px;
}
.pretension-chat__conclusion-title{
  background: #2278B2;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.pretension-chat__conclusion-text{
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}

.pretension-chat__input-group{
  margin-bottom: 16px;
  textarea{
    background: #FFFFFF;
    border: 1px solid #2278B2;
    box-sizing: border-box;
    border-radius: 4px;
    resize: none;
    width: 100%;
    padding: 16px;
  }
}
.pretension-chat__btn{
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #77d202;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s ease-in-out;
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #fff;
    color: #77d202;
  }
}
