.filter-dropdown{
  position: relative;
}
.filter-dropdown__button{
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #2278B2;
  display: flex;
  align-items: center;
  margin-right: 3px;
  i:nth-child(1){
    font-size: 14px;
    margin-right: 6px;
  }
  span{
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-right: 8px;
  }
  i:last-child{
    font-size: 8px;
  }
}
.filter-dropdown__box{
  width: 200px;
  display: none;
  position: absolute;
  bottom: -6px;
  right: 0;
  transform: translateY(100%);
  background: #FFFFFF;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px;
  z-index: 9;

  &.open{
    display: block;
  }
}
.filter-dropdown__item{
  width: 100%;
  box-sizing: border-box;
  background: #F7FCFF;
  border-radius: 4px;
  padding: 14px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  color: #303030;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  &:not(:last-child){
    margin-bottom: 4px;
  }
  &:hover{
    background: #ddf9ff;
  }
  &.active{
    pointer-events: none;
    background: #2278B2;
    color: #fff;
    i{
      color: #fff;
      &.icon-lamp{
        color: #fff
      }
    }
  }
  i{
    margin-right: 11px;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.icon-lamp{
      color: #77D202;
    }
    &:before{
      margin: 0;
    }
  }
}
