.footer {
  margin-top: auto;
  height: 60px;
  background: #2d90d3;

  .container {
    height: 100%;
  }

  .footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  p {
    font-size: 16px;
    line-height: 150%;
    color: #fff;
  }

  a {
    display: flex;
    align-items: center;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.6;
    }

    span {
      padding-right: 20px;
      font-size: 16px;
      line-height: 150%;
      color: #fff;
    }
  }
}
