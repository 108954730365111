.document-right {
  padding: 36px 0 0 32px;
  width: 50%;
  flex-shrink: 0;
  margin-left: auto;
}

.document-right__wrapper {
  padding: 32px 35px;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(34, 120, 178, 0.14);
  border-radius: 4px;

  &--error {
    .checkbox__wrapper {
      .checkbox__tick-wrapper {
        border-color: red;
      }

      .checkbox__text {
        color: red;
      }
    }
  }
}

.document-right__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: #2d90d3;
  padding-bottom: 20px;
}

.document-right__content {
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: #303030;
    padding-bottom: 20px;
  }
}
