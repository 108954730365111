.pretension-create {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
}

.pretension-create__info{
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(34, 120, 178, 0.12);
  border-radius: 4px;
  width: 740px;
  flex-shrink: 0;
  margin-left: 34px;
  margin-bottom: 70px;
  padding: 32px;
  &.hidden{
    display: none;
  }
  h5{
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #303030;
    padding-left: 56px;
    padding-bottom: 24px;
    padding-top: 4px;
    border-bottom: 1px solid #ECECEC;
    position: relative;
    margin-bottom: 24px;
    &:before{
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-image: url("../../../images/question.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
    }
  }
  h6{
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #303030;
    margin-bottom: 16px;
  }
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #929292;
    margin-bottom: 24px;
  }
  ol {
    counter-reset: number;
    list-style-type: none;
  }
  ol{
    li{
      padding-left: 56px;
      font-size: 16px;
      line-height: 24px;
      color: #929292;
      margin-bottom: 24px;
      position: relative;
      &:before{
        counter-increment: number;
        content: counter(number)"\a0";
        position: absolute;
        left: 14px;
        top: 12px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        z-index: 2;
      }
      &:after{
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #2278B2;
        z-index: 1;
      }
    }
  }
}


.pretension-create__main{
  width: 100%;
}

