.pretension-item{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-bottom: none;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 32px;
  height: 80px;
  &.edited{
    background: #a2e9ff;
  }
  &.problem{
    background: rgba(255, 105, 105, 0.54);
  }
  &:first-child{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child{
    border-bottom: 1px solid #ECECEC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.pretension-item__col{
  flex-shrink: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  &.col-number{
    button{
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #2278B2;
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  &.col-photo{
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      display: none;
      font-size: 23px;
      color: #2278B2;
    }
    &.has-photo{
      i{
        display: block;
      }
    }
  }
  &.col-edits{
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      display: none;
      font-size: 18px;
      color: #2278B2;
    }
    &.has-edits{
      i{
        display: block;
      }
    }
  }
  &.col-btn{
    padding-right: 36px;
    button{
      width: 24px;
      height: 24px;
      border: none;
      cursor: pointer;
      background: #2278B2;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        color: #fff;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }


}

.pretension-status{
  position: relative;
  padding-left: 30px;
  display: flex;
  align-items: center;
  i{
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
      font-size: 20px;
      margin: 0;
    }
  }
  &.status-draft{
    i{
      color: #9B51E0;
    }
  }
  &.status-sent{
    i{
      color: #219653;
    }
  }
  &.status-processing{
    i{
      color: #FFB342;
    }
  }
  &.status-answered{
    i{
      color: #0AC1FB;
    }
  }
  &.status-error{
    i{
      color: #EB5757;
    }
  }
  &.status-done{
    i{
      color: #14BD25;
    }
  }
  &.status-closed{
    i{
      color: #929292;
    }
  }
}
