.mails {
  display: flex;
  padding: 52px 40px 40px;
  box-sizing: border-box;
  width: 89.6875%;
  background: #fff;
}
.mails__content{
  padding-right: 30px;
  max-width: 1049px;
  width: 80%;
  flex-shrink: 0;
}

.mails__headline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mails__headline {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  background: linear-gradient(45deg, #0c65a0 0%, #2d90d3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 1499px) {
    font-size: 32px;
  }
}

.mails__button-instructions {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #77d202;
  border: 1px solid #77d202;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
  transition: 0.3s ease-in-out;

  @media (max-width: 1499px) {
    height: 42px;
    padding-left: 30px;
    font-size: 15px;
    padding-right: 30px;
  }

  &:hover {
    background: #77d202;
    color: #fff;
  }
}
.mails-right:not(.empty){
  margin-left: auto;
}

.mails__content-search-error {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: red;
  width: 100%;
  max-width: 650px;
}

.mails__content-search-error-button {
  color: #2278b2;
  cursor: pointer;
  transition: 0.3s ease;
  background: transparent;
  padding-left: 4px;
  border: none;

  &:hover {
    color: #4182b0;
  }
}
.mails-right{
  padding-top: 74px;
  width: 100%;
}
.mails-right__content{
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(34, 120, 178, 0.12);
  border-radius: 4px;
  width: 100%;
  padding: 32px;
  h1, h2, h3, h4, h5, h6{
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    color: #303030;
  }
  p{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }

}

// undistributed
.mails-list{
  width: 100%;
  padding-top: 10px;
  position: relative;
  .col-check{
    //width: 8.5%;
    display: none;
  }
  .col-number{
    width: 21.5%;
  }
  .col-date{
    width: 28.8%;
  }
  .col-type{
    width: 27%;
  }
  .col-btn{
    width: 14.2%;
  }
  .no-results{
    display: none;
  }
  &.empty{
    .mails-list__header{
      opacity: 0;
    }
    .mails-list__footer{
      opacity: 0;
    }
    .mails-list__bottom-nav{
      opacity: 0;
    }
    .has-results{
      display: none;
    }
    .no-results{
      display: block;
    }
  }
}
.mails-list__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0 12px 33px;
  box-sizing: border-box;
}
.mails-list__header-item{
  flex-shrink: 0;
  button.mails-list__header-item-text{
    cursor: pointer;
    transition: .3s ease;
    &:hover{
      opacity: .6;
    }
  }
}

.mails-list__header-item-text{
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #929292;
  background: transparent;
  border: none;
  cursor: default;
  padding: 0;
}
.mails-list__notification{
  background: #F4FBFF;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  i{
    color: #2278B2;
    margin-right: 8px;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
  }
  span{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
  }
}

.mails-list__bottom-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
}

.mail-item{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-bottom: none;
  box-sizing: border-box;
  width: 100%;
  &.new{
    .mail-item__heading{
      background: #a2e9ff;
    }
  }
  &:first-child{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child{
    border-bottom: 1px solid #ECECEC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.mail-item__heading{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 32px;
  height: 78px;
  border: none;
  text-align: left;
  background: transparent;
}
.mail-item__col{
  flex-shrink: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
  &.col-number{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #2278B2;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  &.col-type{
    display: flex;
    align-items: center;
    .mail-status{
      display: flex;
      align-items: center;
      i{
        margin-right: 12px;
        width: 24px;
        height: 24px;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        &:before{
          margin: 0;
        }
      }
      &.status-brak{
        i{
          color: #EB5757;
        }
      }
      &.status-stop{
        i{
          color: #EB5757;
        }
      }
      &.status-recontrol{
        i{
          color: #000000;
        }
      }
      &.status-response{
        i{
          color: #FFB342;
        }
      }
    }
  }
  &.col-btn{
    padding-right: 31px;
    display: flex;
    justify-content: space-between;
  }
}
.mail-item__button-open{
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background: #2278B2;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  i{
    color: #fff;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
  }
}
.mail-item__button-download{
  width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  i{
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    color: #77D202;
    display: flex;
    &:before{
      margin: 0;
    }
  }
}
.mail-item__content{
  overflow: hidden;
  height: 2000px;
  max-height: 0;
  transition: 0.2s;
  padding: 0 31px;
  position: relative;
  .main_preloader{
    padding-bottom: 62px;
  }
  &.open{
    max-height: 663px;
  }
  .rcs-custom-scroll .rcs-outer-container .rcs-custom-scrollbar {
    opacity: 1;
  }
}
.mails-list__footer{
  //display: flex;
  display: none;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ECECEC;
  padding: 16px 0;
  position: relative;
}
.mails-list__footer-check{
  padding-left: 33px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2278B2;
  .checkbox__wrapper{
    margin-right: 16px;
  }
}
.mails-list__footer-checked{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #929292;
  display: flex;
  align-items: center;
}
.footer-checked__val{
  width: 25px;
  height: 25px;
  background: #2278B2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 50%;
  span{
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }
}
.footer-download__button{
  background: #77D202;
  border-radius: 4px;
  padding: 12px 45px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
  cursor: pointer;
  i{
    display: flex;
    font-size: 24px;
    height: 24px;
    margin-right: 15px;
    &:before{
      margin: 0;
    }
  }
}

.mail-factures{
  border-top: 1px solid #ECECEC;
  //padding-left: 69px;
  .col-number{
    width: 56%;
    padding: 0 16px
  }
  .col-date{
    width: 44%;
    padding: 0 16px
  }
}
.mail-factures__heading{
  display: flex;
  padding-top: 24px;
  padding-bottom: 8px;
}
.mail-factures__list{

}
.mail-facture{
  border-radius: 4px;
  display: flex;
  padding: 14px 0;
  &:not(:last-child){
    margin-bottom: 4px;
  }
  &:nth-child(odd){
    background: #FAFAFA;
  }
  .col-number{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2278B2;
  }
  .col-date{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
  }
}
.mail-factures__nav{
  position: relative;
}
.mail-factures__paginate{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.mail-factures__total{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #929292;
  padding-left: 16px;
}
.mail-factures__per-page{

}
.mail-factures__per-page-label{
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.mails{
  .filter-dropdown{

  }
}
