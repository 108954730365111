.pretension-search-products{
  display: flex;
  align-items: center;
  padding-top: 24px;
  .pretension-search-factures__form{
    flex: unset;
    margin-left: auto;
  }
  .pretension-search-factures__form-wrapper{
    width: 289px;
  }
}

.pretension-search-products__headline{
  font-size: 20px;
  line-height: 24px;
  color: #303030;
  margin-right: 24px;
}
.pretension-search-products__tabs{
  display: flex;
  align-items: center;
  .tab-button{
    width: 154px;
    height: 48px;
    border: 1px solid #77D202;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    font-size: 16px;
    line-height: 24px;
    color: #77D202;
    transition: .3s;
    background: #fff;
    cursor: pointer;
    &:hover{
      color: #fff;
      background: #77D202;
    }
    &.active{
      color: #fff;
      background: #77D202;
      cursor: default;
      pointer-events: none;
    }
  }
}
