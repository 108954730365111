.search-documents {
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-bottom: 22px;
  width: 50%;
  border-bottom: 1px solid #ececec;
}

.search-documents__headline {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #303030;
  margin-right: 20px;

  @media (max-width: 1599px) {
    font-size: 16px;
    margin-right: 15px;
  }
}

.search-documents__form {
  display: flex;
  align-items: center;
  flex: 1;
}

.search-documents__form-wrapper {
  flex: 1;
  padding-right: 15px;
  position: relative;
}

.search-documents__input {
  width: 100%;
  height: 46px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #303030;
  padding: 0 16px;
  box-sizing: border-box;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #303030;
  }
  &.input-error{
    border: 1px solid red;
  }
}

.search-documents__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #77d202;
  border-radius: 4px;
  width: 7.8125vw;
  max-width: 150px;
  min-width: 110px;
  height: 46px;
  border: 1px solid #77d202;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background: #fff;
    color: #77d202;
  }
}

.search-documents__submit-icon {
  font-size: 22px;
  margin-right: 6px;

  @media (max-width: 1599px) {
    font-size: 18px;
    margin-right: 3px;
  }
}

.search-documents__submit-text {
  font-size: 16px;
  line-height: 1.5;
}
